//
//
//
//
//
//

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    aspectRatio: {
      type: String,
      default: 'original',
    },
    size: {
      type: String,
      default: 'full', // small, medium, full
    },
  },

  computed: {
    url() {
      let url = this.src
      let ratio = ''
      if (this.aspectRatio === '16:9') {
        ratio = ',h_1080,w_1920'
      }
      url = url.replace('.mp4', '.FORMAT')
      url = url.replace('.mov', '.FORMAT')
      url = url.replace('/upload/', '/upload/c_fill' + ratio + '/')
      return url
    },
  },
}
